import React, { FC, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Scrollbar } from "swiper"
import "swiper/swiper-bundle.css"
import "swiper/components/pagination/pagination.scss"
import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"
import styled from "styled-components"
import { Container } from "./Shared/Container"
import { breakpoint } from "styles/breakpoints"
import { colors } from "styles/colors"
import { formatImgSrcUrl } from "utils"

SwiperCore.use([Navigation, Scrollbar])

interface IGalleryProps {
  photos?: [{ image: { id: string; sourceUrl: string } }]
}

const StyledContainer = styled(Container)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100%;
  & * {
    height: 100%;
  }
  & img {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    background-size: cover;
    background-position: center;
  }
  & .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .swiper-button-prev,
  .swiper-button-next {
    height: auto;
    color: ${colors.white};
  }
`

const MasonryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  & img {
    cursor: pointer;
    object-fit: cover;
    height: auto;
    max-height: 150px;
    max-width: 150px;
    margin: 20px;
  }
  @media ${breakpoint.md} {
    & img {
      cursor: pointer;
      max-width: 300px;
      max-height: 300px;
      margin: 20px;
    }
  }
  & img :hover {
    opacity: 0.4;
  }
`

const Gallery: FC<IGalleryProps> = ({ photos }) => {
  const [slider, setSlider] = useState(false)
  const [slide, setSlide] = useState(0)

  const toggleSlider = e => {
    const toggle =
      e.target.classList.contains("swiper-button-next") ||
      e.target.classList.contains("swiper-button-prev")

    if (!toggle) {
      return slider ? setSlider(false) : showSlider(e)
    }
  }

  const showSlider = e => {
    const index = e.target.id
    setSlide(index)
    e.target.blur()
    setSlider(true)
  }

  return (
    <div>
      {slider ? (
        <StyledContainer onClick={toggleSlider}>
          <Swiper
            slidesPerView={1}
            centeredSlides
            initialSlide={slide}
            navigation
            scrollbar={{ draggable: true }}
          >
            {photos.map(slide => {
              return (
                <SwiperSlide key={slide.image.id}>
                  <img
                    src={
                      slide.image && slide.image.sourceUrl
                        ? slide.image.sourceUrl
                        : null
                    }
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </StyledContainer>
      ) : null}
      <MasonryContainer>
        {photos.map((slide, index) => {
          if (!slide.image) return null
          return (
            <img
              id={index}
              src={formatImgSrcUrl(
                slide.image && slide.image.sourceUrl
                  ? slide.image.sourceUrl
                  : null
              )}
              key={slide.image.id}
              onClick={toggleSlider}
            />
          )
        })}
      </MasonryContainer>
    </div>
  )
}

export default Gallery
