import React, { FC } from "react"
import { IPageTemplateProps } from "./Page"
import { Container } from "components/Shared/Container"
import { PageTitleWithDateSmall } from "components/Shared/PageTitle"
import { formatDate } from "utils"
import SEO from "components/Seo"
import Breadcrumbs from "components/Breadcumbs"
import Gallery from "components/Gallery"

const PhotosTemplate: FC<IPageTemplateProps> = ({ pageContext, ...props }) => {
  const {
    title,
    date,
    seo,
    photos: { photos },
  } = pageContext

  return (
    <>
      <SEO
        title={seo ? seo.title : title}
        description={seo ? seo.metaDesc : null}
        image={
          seo && seo.opengraphImage && seo.opengraphImage.sourceUrl
            ? seo.opengraphImage.sourceUrl
            : null
        }
      />
      <Container>
        <PageTitleWithDateSmall>
          {title}
          <span>{formatDate(date)}</span>
        </PageTitleWithDateSmall>
        <Breadcrumbs
          breadcrumbs={pageContext.seo ? pageContext.seo.breadcrumbs : null}
        />
      </Container>
      <Gallery photos={photos} />
    </>
  )
}

export default PhotosTemplate
